import styled from "styled-components";

export const H1Styled = styled.h1`
  width: 80%;
  color: #ffffff;
  font-family: 'unbounded', Arial, Helvetica, sans-serif;
  font-size: 4.4rem;
  font-weight: bold;
  
  @media(max-width: 500px) {
    width: 100%;
  }
`

export const H2Styled = styled.h2`
  color: #ffffff;
  font-family: 'unbounded', Arial, Helvetica, sans-serif;
  font-size: 2.2rem;
  max-width: 100%;
`