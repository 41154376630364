
import { ButtonDefault, LangSwitchBtn } from "./components/buttons/buttons";
import { ButtonsAndHeadersContainer, ButtonsMainContainer, HeadersContainer, HomepageMainContainer, LogoAndLangContainer, LogoContainer, QRContainer } from "./components/containers/containers";
import { H1Styled, H2Styled } from "./components/headers/headers";
import Logo from "./components/logo/Logo";
import GlobalStyle from "./styles/globals";
import QR from './static/QR_homepage.png'
import { useState } from "react";
import LogoEng from "./components/logo/LogoEng";


function App() {
  document.title = 'Потенциал Страны. Карьера.'
  const [switchLang, setSwitchLang] = useState('eng')
  if (switchLang === 'eng') {
    return (
      <HomepageMainContainer>

        <GlobalStyle />
        <LogoAndLangContainer>
          <LogoContainer>

            <Logo />
          </LogoContainer>
          <QRContainer>
            <img alt="QR Code" style={{ width: '156px', height: '156px'}} src={QR} />
          </QRContainer>

        </LogoAndLangContainer>
        <ButtonsAndHeadersContainer>
          <HeadersContainer>
            <H1Styled>ПОТЕНЦИАЛ СТРАНЫ</H1Styled>
            <H2Styled>Экосистема социальных, молодежных <br/>и образовательных проектов Фонда Росконгресс</H2Styled>
          </HeadersContainer>
          <ButtonsMainContainer>

            <ButtonDefault onClick={() => {
              window.ym(98731152,'reachGoal','projectsClick')
              console.log('click')
            }} href="https://projects.roscongress.org/stendinitsiativfondaroskongress_aukf/"> Проекты Экосистемы</ButtonDefault>

            <ButtonDefault onClick={() => {
              window.ym(98731152,'reachGoal','botClick')
              console.log('click')
            }} href="/chatbot/">Чат-бот</ButtonDefault>


          </ButtonsMainContainer>
        </ButtonsAndHeadersContainer>
        <LangSwitchBtn onClick={() => setSwitchLang('ru')}>{switchLang}</LangSwitchBtn>
      </HomepageMainContainer>
    );
  }
  else {
    return (
      <HomepageMainContainer>
        <GlobalStyle />
        <LogoAndLangContainer>
          <LogoContainer>
            <LogoEng />
          </LogoContainer>
          <QRContainer>
            <img alt='QR Code' style={{ width: '156px', height: '156px' }} src={QR} />
          </QRContainer>
        </LogoAndLangContainer>
        <ButtonsAndHeadersContainer>
          <HeadersContainer>
            <H1Styled>THE COUNTRY’S HUMAN POTENTIAL</H1Styled>
            <H2Styled>The ecosystem of the Roscongress Foundation’s<br/> social, youth and educational projects and initiatives</H2Styled>
          </HeadersContainer>
          <ButtonsMainContainer>

            <ButtonDefault onClick={() => {
              window.ym(97518456, 'reachGoal', 'projectsClick')
              console.log('click')
            }} href="https://projects.roscongress.org/en/projects_cihk/"> ECOSYSTEM PROJECTS</ButtonDefault>

            <ButtonDefault onClick={() => {
              window.ym(97518456, 'reachGoal', 'botClick')
              console.log('click')
            }} href="/chatbot/">CHAT BOT</ButtonDefault>


          </ButtonsMainContainer>
        </ButtonsAndHeadersContainer>

        <LangSwitchBtn onClick={() => setSwitchLang('eng')}>{switchLang}</LangSwitchBtn>
      </HomepageMainContainer>
    )
  }
}

export default App;
