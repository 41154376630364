import styled from "styled-components";


export const ButtonDefault = styled.a`
  color: #ffffff;
  font-size: 2rem;
  background-color: transparent;
  padding: 1rem 5.7rem;
  font-weight: bold;
  text-transform: uppercase;
  border: 2px solid #ffffff;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #F61067;
    background-color: #ffffff;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  @media(max-width: 1024px) {
    font-size: 2rem;
    padding: 2.5rem 3.4rem;
  }
`

export const ProjectsButton = styled(ButtonDefault)`

  background-color: #D34062;

  &:hover, :active {
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #D34062;
    filter: drop-shadow(0 0 0.3rem #D34062);
  }
`

export const BotButton = styled(ButtonDefault)`
  background-color: #D34062;

  &:hover, :active {
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #D34062;
    filter: drop-shadow(0 0 0.3rem #D34062);
  }
`

export const LangSwitchBtn = styled(ButtonDefault)`
  align-self: flex-end;
  width: 11.5rem;
  padding: 10px;
  margin-bottom: 4.8rem;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    align-self: flex-start;
    justify-content: flex-end;
  }
`