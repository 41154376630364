import styled from "styled-components";

export const HomepageMainContainer = styled.div`
  font-family: 'unbounded', Arial, Helvetica, sans-serif;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #ffffff;
  padding: 0 6.3rem;
  @media(max-width: 1024px) {
    padding: 0 3rem;
  }
`

export const HeadersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'unbounded', Arial, Helvetica, sans-serif;
  gap: 1.1rem;
  width: 100%;
`

export const ButtonsMainContainer = styled.div`
  width: 100%;
  display: flex;  
  gap: 3.2rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.8rem;
    width: 100%;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  gap: 10%;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 7%;
`

export const LogoAndLangContainer = styled.div`
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const ButtonsAndHeadersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6.4rem;
  margin-bottom: 30px;
`

export const QRContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    display: none;
  }
`